<script>
import groupBy from 'lodash/groupBy'

export default {
	props: {
		verticalCard: { type: Boolean, default: true },
		top: Boolean,
	},
	inject: ['manager'],
	computed: {
		tags() {
			return this.$srv('productCardTags', [])
		},
		matchedAttrs() {
			return this.tags
				.flatMap((tag) => {
					if (tag.collectionId) {
						let inCollection = this.manager.product.collectionsIds?.some((c) => c == tag.collectionId)

						if (!inCollection) return false
					}

					const attrs = this.manager.product.variants.flatMap((v) => v.attrs)
					return attrs.filter((attr) => attr.attrKey.k == tag.attrKeyId)
				})
				.filter(Boolean)
		},
		resultantAttrs() {
			if (!this.matchedAttrs?.length) return null

			let group = groupBy(this.matchedAttrs, (t) => t.attrKeyId)
			let keys = Object.keys(group)
			let resultantTags

			if (keys?.length) {
				resultantTags = keys.map((k) => {
					for (const g of group[k]) {
						return g
					}
				})
				if (resultantTags?.length < 3) {
					keys.forEach((key) => {
						let attribute = resultantTags.find((r) => r.attrKeyId == key)
						let result = group[key].find((g) => g.v != attribute.v)
						if (result) resultantTags.push(result)
					})
				}
				return resultantTags.slice(0, 4)
			}

			return resultantTags.slice(0, 4)
		},
		verticalClasses() {
			let design = this.manager.configData.name.toLowerCase()
			if (this.manager.small && (design.includes('4') || design.includes('6'))) {
				return `product-card-tags-vertical-${design} product-card-tags-vertical-${design}--small`
			}
			return `product-card-tags-vertical-${design}`
		},
		horizontalClasses() {
			let design = this.manager.configData.name.toLowerCase()
			if (this.manager.featured) return `product-card-tags-with-timer-horizontal-${design}`
			else return `product-card-tags-horizontal-${design}`
		},
	},
}
</script>

<template>
	<div v-if="tags.length" class="product-card__attrs-tags">
		<div v-if="resultantAttrs && resultantAttrs.length">
			<v-container
				:class="[$bem('product-card-tags-vertical'), verticalClasses]"
				class="pa-0 pt-1"
				v-if="verticalCard"
			>
				<div :class="$bem('__attrs-wrapper')">
					<div
						class="d-flex align-center"
						v-if="resultantAttrs"
						:class="{
							'justify-center': manager.configData.alignment == 'center' && !top,
							'justify-end': manager.configData.alignment == 'right' && !top,
							'px-4': top && !manager.small,
							'py-1': manager.small,
						}"
					>
						<div v-for="({ v }, index) of resultantAttrs" :key="index" class="d-flex flex-column">
							<span
								class="px-1 font-0 mr-1 secondary font-weight-medium d-inline-block line-clamp-1 py-1"
								:class="[
									$bem('__attr-value'),
									{ 'product-card-tags-vertical__attr-value--max-width-mobile': $b.m },
								]"
							>
								{{ v }}
							</span>
						</div>
					</div>
				</div>
			</v-container>
			<v-container
				:class="[$bem('product-card-tags-horizontal'), horizontalClasses]"
				class="pa-0 mb-1"
				v-else
			>
				<div class="d-flex align-center" v-if="resultantAttrs">
					<div v-for="({ v }, index) of resultantAttrs" :key="index" class="d-flex flex-column my-1">
						<span
							class="px-2 font-0 mr-1 secondary font-weight-medium d-inline-block line-clamp-1"
							:class="$bem('__attr-value')"
						>
							{{ v }}
						</span>
					</div>
				</div>
			</v-container>
		</div>
	</div>
</template>

