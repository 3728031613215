<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'
import hooksMixin from '@/v-shop/mixins/hooks-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin, hooksMixin('ProductCard')],
	cssVars: {
		selector: '.product-card',
	},
	computed: {
		wrapperClass() {
			if (this.$b.m) {
				if (!this.forMobileShop) {
					if (this.btnHover) return 'product-card-design5-small-wrapper-mobile-forBtn'
					else return 'product-card-design5-small-wrapper-mobile'
				} else {
					if (this.btnHover) return 'product-card-design5-small-wrapper-forBtn'
					else return 'product-card-design5-small-wrapper'
				}
			} else if (this.$b.t) {
				if (this.btnHover) return 'product-card-design5-small-wrapper-forBtn'
				else return 'product-card-design5-small-wrapper-mobile'
			} else return 'product-card-design5-small-wrapper'
		},
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div
		class="h100"
		:class="wrapperClass"
		v-if="verticalCard"
		v-click-outside="forMobileShop && onLinkBlurMobile"
	>
		<v-hover v-model="hover">
			<v-card
				:hover="!outOfStock ? true : false"
				elevation="2"
				@mouseover="firstImageHover = true"
				@click="cardClick"
				:ripple="false"
				:class="[$bem('product-card-design5-small'), { cardBorder: cardBorder }]"
				class="d-flex flex-column rounded-md overflow-hidden justify-space-between base product-card"
				:style="{ height: forMobileShop && btnHover && hover ? 'calc(100% + 50px)' : '100%' }"
			>
				<div :class="[$bem('__image-cont'), { 'no-stock': outOfStock }, 'mx-2 mt-2']">
					<hook zone="image">
						<Media
							cover
							:class="$bem('__main-image')"
							:src="mainImage"
							width="100%"
							aspect-ratio="1"
							:imgClass="
								$b.mt
									? 'product-card-design5-small__image--smallWidth-mobile'
									: 'product-card-design5-small__image--smallWidth'
							"
						/>
						<div v-if="secondImage && firstImageHover && imgHover" :class="$bem('__second-image-cont')">
							<Media
								cover
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
								:imgClass="
									$b.mt
										? 'product-card-design5-small__image--smallWidth-mobile'
										: 'product-card-design5-small__image--smallWidth'
								"
							/>
						</div>
					</hook>

					<hook zone="offer">
						<LimitedTimeOffer
							:product="product"
							v-if="hasLimitedTimeOffer"
							:class="$bem('__offer', 'px-1')"
							textAlign="end"
						/>
					</hook>

					<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="25px" />
				</div>

				<div
					:class="[
						$bem('__data-cont'),
						'd-flex flex-column mx-2 h100',
						{
							'no-stock': outOfStock,
							'justify-space-between': btn && forMobileShop,
						},
					]"
				>
					<ProductCardPrice
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						:containerClass="[
							'd-flex align-center line-clamp-1 px-1 mt-2',
							{ 'justify-center': alignment == 'center', 'justify-end': alignment == 'right' },
						]"
						:prevPriceClass="[$bem('__prev-price'), 'grey--text mr-1 font-0']"
						:priceClass="[$bem('__price'), 'price font-3 line-clamp-1']"
						:discountClass="[$bem('__discount'), 'discount pa-2']"
						:elementOrder="{ prevPrice: 1, price: 2 }"
					/>

					<hook zone="attrs&financingTags">
						<ProductCard-FinancingTag />
					</hook>

					<hook zone="textTags">
						<ProductCardText
							:textClass="[
								$bem('__text-tags'),
								'font-0 line-clamp-1 px-1 primary rounded mb-1',
								{
									'alignment-center': alignment == 'center',
									'alignment-right': alignment == 'right',
								},
							]"
							:conditional="hasTextTag"
							:text="textTag"
						/>
					</hook>

					<router-link :to="productRoute" custom v-slot="{ navigate, href }">
						<a
							class="card-anchor header"
							@click="forMobileShop ? onLinkClickMobile(navigate, $event) : navigate($event)"
							:href="href"
						>
							<hook zone="name">
								<ProductCardText
									:textClass="[
										$bem('__name'),
										'line-clamp-2 font-1 header px-1',
										alignment == 'left'
											? 'text-start'
											: alignment == 'center'
											? 'text-center'
											: 'text-end',
										{ 'pb-1': !mobileBtn && !brand },
									]"
									:text="product.name"
								/>
							</hook>
						</a>
					</router-link>

					<hook zone="brand">
						<div :class="$bem('__brand-wrapper')">
							<ProductCardText
								:textClass="[
									$bem('__brand'),
									'font-0 line-clamp-1 px-1',
									alignment == 'left'
										? 'text-start'
										: alignment == 'center'
										? 'text-center'
										: 'text-end',
									{ 'mb-1': !mobileBtn },
								]"
								:conditional="!!brand"
								:text="brand"
							/>
						</div>
					</hook>
					<ProductCardRating :product="product" />

					<ProductCard-AttrsTags />

					<ProductCard-QtyInput />

					<div
						class="pa-3"
						v-if="mobileBtn && !$b.d && hasBtn"
						:class="{
							btnHoverVertical: btnHover && forMobileShop,
							'btnHoverVertical--show': btnHover && hover && forMobileShop,
							z1: product.hasUniqueVariant && !product.info.customizationKey,
						}"
					>
						<hook zone="btn">
							<v-btn
								class="cta w100 rounded-md"
								:disabled="buttonText == 'Sin stock'"
								:loading="btnIsLoading"
								@click="btnClicked"
							>
								{{ buttonText | lang }}
							</v-btn>
						</hook>
					</div>

					<hook zone="wishlist-toggler" :class="{ 'no-stock': outOfStock }">
						<ProductWishlistToggler
							:productId="product.id"
							:class="$bem('__wishlist-toggler')"
							style="z-index: 1"
						/>
					</hook>
				</div>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD -->
	<div
		class="w100"
		v-else
		:class="{
			'product-card-design5-small-horizontal-wrapper-forBtn': btnHover,
		}"
		v-click-outside="forMobileShop && onLinkBlurMobile"
	>
		<v-hover v-model="hover">
			<v-card
				:hover="!outOfStock ? true : false"
				@mouseover="firstImageHover = true"
				outlined
				@click="cardClick"
				:ripple="false"
				:class="[
					$bem('product-card-design5-small-horizontal'),
					{ 'product-card-design5-small-horizontal-forBtn': hasBtn, cardBorder: cardBorder },
				]"
				class="d-flex overflow-hidden base product-card py-2"
			>
				<div
					:class="[
						$bem('__image-cont', 'col col-5 pa-0 d-flex flex-column justify-center'),
						{ 'no-stock': outOfStock },
					]"
				>
					<hook zone="imageHorizontal" class="h100 p-relative">
						<Media
							:class="$bem('__main-image', 'px-1 h100')"
							:src="mainImage"
							width="100%"
							imgClass="product-card-design5-small-horizontal__image--smallWidth"
						/>

						<div
							v-if="secondImage && firstImageHover && imgHover"
							:class="$bem('__second-image-cont', 'px-1 h100')"
						>
							<Media
								:src="secondImage"
								width="100%"
								:class="[
									$bem('__second-image', 'h100'),
									hover ? $bem('__second-image--show') : '',
									'h100',
								]"
								imgClass="product-card-design5-small-horizontal__image--smallWidth"
							/>
						</div>
					</hook>

					<hook zone="offerHorizontal">
						<LimitedTimeOffer
							:product="product"
							textAlign="end"
							class="pa-1 line-clamp-1"
							:class="$bem('__offer')"
						/>
					</hook>

					<hook zone="event-iconHorizontal">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="30px" />
					</hook>
				</div>

				<div
					class="col col-7 py-0 pl-1 d-flex flex-column justify-center"
					:class="[$bem('__data-cont'), { 'no-stock': outOfStock, 'my-5': !hasBtn }]"
				>
					<ProductCardPrice
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						:containerClass="['d-flex align-center line-clamp-1', { 'mt-5': !hasBtn }]"
						:prevPriceClass="['grey--text font-0 mr-1', $bem('__prev-price')]"
						:priceClass="[$bem('__price'), 'price font-4 line-clamp-1']"
						:discountClass="[$bem('__discount'), 'discount pa-2']"
						horizontalCard
						:elementOrder="{ prevPrice: 1, price: 2 }"
					/>

					<hook zone="attrs&financingTagsHorizontal">
						<div :class="$bem('__financing-wrapper')">
							<div
								:class="[
									$bem('__financing'),
									'font-0 line-clamp-1 px-1 primary rounded',
									{
										'mb-1': !hasTextTag && discountPct,
									},
								]"
								v-if="!!financingTagHtml"
								v-html="financingTagHtml"
							/>
						</div>
					</hook>

					<hook zone="textTagsHorizontal">
						<ProductCardText
							:textClass="[$bem('__text-tags'), 'font-0 line-clamp-1 px-1 primary rounded']"
							:conditional="hasTextTag"
							:text="textTag"
						/>
					</hook>

					<router-link :to="productRoute" custom v-slot="{ navigate, href }">
						<a
							class="card-anchor header"
							@click="forMobileShop ? onLinkClickMobile(navigate, $event) : navigate($event)"
							:href="href"
						>
							<hook zone="nameHorizontal">
								<ProductCardText
									:textClass="[
										$bem('__name'),
										'line-clamp-2 font-2 header',
										{ 'pb-2': !hasBtn && !brand },
									]"
									:text="product.name"
								/>
							</hook>
						</a>
					</router-link>

					<hook zone="brand">
						<div :class="$bem('__brand-wrapper')">
							<ProductCardText
								:textClass="[$bem('__brand'), 'font-0 line-clamp-1', { 'pb-2': !hasBtn }]"
								:conditional="!!brand"
								:text="brand"
							/>
						</div>
					</hook>

					<hook zone="wishlist-togglerHorizontal">
						<ProductWishlistToggler
							:productId="product.id"
							:class="[$bem('__wishlist-toggler'), { 'no-stock': outOfStock }]"
							style="z-index: 1"
							:height="30"
							:width="30"
						/>
					</hook>
					<ProductCardRating :product="product" />

					<div
						class="py-2"
						v-if="hasBtn && mobileBtn"
						:class="{
							btnHoverHorizontal: btnHover && forMobileShop,
							'btnHoverHorizontal--show': (btnHover && showBtnHoverMobile) || (btnHover && hover),
							z1: product.hasUniqueVariant && !product.info.customizationKey,
						}"
					>
						<hook zone="btn">
							<v-btn
								class="cta w100 rounded-md"
								:disabled="buttonText == 'Sin stock'"
								:loading="btnIsLoading"
								@click="btnClicked"
							>
								{{ buttonText | lang }}
							</v-btn>
						</hook>
					</div>
				</div>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF HORIZONTAL CARD -->
</template>

<style lang="scss" scoped>
.cardBorder {
	border: 1px solid #b2b2b2;
}

.no-stock {
	opacity: 0.4;
}

// VERTICAL CARD CLASSES
.product-card-design5-small-wrapper {
	min-height: 270px;
	width: 100%;
	min-width: 100px;

	&-forBtn {
		min-height: 270px;
		width: 100%;
		min-width: 100px;
		padding-bottom: 50px;
	}
}

.product-card-design5-small-wrapper-mobile {
	width: 90%;

	&-forBtn {
		width: 90%;
	}
}

.product-card-design5-small {
	position: relative;
	cursor: pointer;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__offer {
		position: absolute;
		bottom: -4px;
	}

	&__event-icon {
		position: absolute;
		bottom: -8px;
		left: 0;
		z-index: 1;
		width: 25px;
	}

	&__name,
	&__brand {
		display: -webkit-box;
	}

	&__wishlist-toggler {
		position: absolute;
		top: 6px;
		right: 6px;
		z-index: 1;
	}

	&__brand-wrapper {
		min-height: 18.19px;
	}

	& ::v-deep .product-card__financing-wrapper {
		min-height: 24.19px;
	}

	& ::v-deep .product-card__attrs-tags {
		min-height: 38.2px;
	}
}

::v-deep .product-card-design5-small {
	.alignment-center {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}

	.alignment-right {
		float: right;
	}

	&__discount {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		border-bottom-right-radius: 4px;
	}

	&__image--smallWidth {
		min-width: 180px;
		min-height: 180px;
		width: 100%;
		height: 100%;
		left: 50% !important;
		top: 50% !important;
		transform: translate(-50%, -50%) !important;
	}

	&__image--smallWidth-mobile {
		max-width: 170px;
		max-height: 170px;
		width: 100%;
		left: 50% !important;
		top: 50% !important;
		transform: translate(-50%, -50%) !important;
	}
}

// HORIZONTAL CARD CLASSES
.product-card-design5-small-horizontal {
	height: 200px;

	&-forBtn {
		height: 240px;
	}

	&__image-cont {
		position: relative;
		width: 200px;
	}

	&__second-image-cont {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__offer {
		position: absolute;
		bottom: 8px;
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	&__event-icon {
		position: absolute;
		bottom: 5px;
		left: 6px;
		z-index: 1;
		width: 25px;
	}

	&__name,
	&__brand {
		display: -webkit-box;
	}

	&__wishlist-toggler {
		position: absolute;
		top: 7px;
		right: 6px;
		z-index: 1;
	}

	&__name {
		min-height: 47.59px;
	}

	&__brand-wrapper {
		min-height: 18.19px;
	}

	&__financing-wrapper {
		min-height: 23.19px;
	}
}

::v-deep div.product-card-design5-small-horizontal {
	&__image--smallWidth {
		width: 80%;
		left: 50%;
		transform: translateX(-50%);
		height: auto;
	}

	&__prev-price {
		display: -webkit-box;
		column-gap: 8px;
	}

	&__discount {
		font-size: 1.1rem;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		border-bottom-right-radius: 4px;
	}

	&__price {
		display: -webkit-box;
	}
}
</style>

