var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$srv('productCardQtySelector', []).length && _vm.manager.hasBtn)?_c('div',{staticClass:"product-card__qty-input",class:{ '--mobile': _vm.$b.m }},[(
			_vm.hasUniqueVariant &&
			_vm.variant.type != 'digital' &&
			_vm.collectionMatch &&
			!_vm.manager.outOfStock &&
			_vm.manager.product.buyable &&
			!_vm.manager.spoHideQtyInput
		)?_c('QtyInput',{attrs:{"max-qty":_vm.maxStockQty,"min-qty":_vm.variant.stock.minBuyableQty,"unit-metric":_vm.manager.product.unitMetric,"pack-metric":_vm.manager.product.packMetric,"step":_vm.variant.metricFactor},model:{value:(_vm.manager.qty),callback:function ($$v) {_vm.$set(_vm.manager, "qty", $$v)},expression:"manager.qty"}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }