<script>
export default {
	props: ['ProductCard'],
	hookZone: [
		'attrs&financingTags',
		'before--image',
		'after--image',
		'before--before-btn',
		'before-btn',
		'after--rating',
	],
	showHook(zone, container) {
		const name = container.configData?.name

		const top = ['Design4', 'Design6']
		const bottom = ['Design2', 'Design5', 'Design7', 'Design8']

		if (container.small) {
			if (name == 'Design4') return zone == 'attrs&financingTags'

			return false
		}

		if (name == 'Design1') {
			return zone == 'before--image'
		} else if (top.includes(name)) {
			return zone == 'attrs&financingTags'
		} else if (bottom.includes(name)) {
			return zone == 'before-btn' || zone == 'after--rating'
		} else return false
	},
	computed: {
		cardType() {
			return this.ProductCard.configData?.name || 'Design1'
		},
		name() {
			return this.ProductCard.configData?.name
		},
		isTop() {
			return ['Design1', 'Design4', 'Design6'].includes(this.cardType)
		},
		withFinancing() {
			if (this.ProductCard.small) return this.name != 'Design5'
			return ['Design4', 'Design6'].includes(this.name)
		},
	},
}
</script>

<template>
	<div
		class="product-card__tags__container"
		:class="{ 'product-card__tags__container--min-height': name == 'Design5' }"
	>
		<ProductCard-AttrsTags
			class="product-card__tags"
			:class="{ 'product-card__tags--top': name == 'Design1' && !ProductCard.small }"
			:product="ProductCard.product"
			:selectedVariant="ProductCard.selectedVariant"
			:verticalCard="ProductCard.verticalCard"
			:financing="withFinancing && ProductCard.financingTagHtml"
			:cardType="ProductCard.configData"
			:featured="ProductCard.featured"
			:small="ProductCard.small"
			:top="isTop"
		/>
	</div>
</template>

<style lang="scss" scoped>
.product-card__tags {
	&__container {
		&--min-height {
			min-height: 39.8px;
		}
	}
	&--top {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
	}
}
</style>

