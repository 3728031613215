<script>
export default {
	props: {
		small: Boolean,
		smallCarousel: Boolean,
		square: Boolean,
		featured: Boolean,
		type: String,
	},

	data() {
		return {
			cardType: this.type || this.$srv('productCardType.name', 'Design6'),
		}
	},
	computed: {
		hasAttrsCnf() {
			return (
				this.$srv('productCardTags', []).length &&
				['design1', 'design4', 'design6'].includes(this.cardType.toLowerCase())
			)
		},
		compName() {
			if (this.small) {
				return `${this.cardType}Small`
			} else if (this.smallCarousel) {
				return `ForSmallCarousel`
			} else if (this.square) {
				return `Sq`
			} else if (this.featured) {
				return `${this.cardType}Featured`
			} else {
				return this.cardType
			}
		},
		sqOrder() {
			if (this.cardType == 'Design1' || this.cardType == 'Design3')
				return {
					name: 1,
					price: 2,
					brand: 3,
				}
			else if (this.cardType == 'Design2')
				return {
					name: 1,
					brand: 2,
					price: 3,
				}
			else if (this.cardType == 'Design4' || this.cardType == 'Design8')
				return {
					brand: 1,
					name: 2,
					price: 3,
				}
			else if (this.cardType == 'Design5' || this.cardType == 'Design6')
				return {
					price: 1,
					name: 2,
					brand: 3,
				}
			else
				return {
					price: 1,
					brand: 2,
					name: 3,
				}
		},
	},
}
</script>

<template>
	<component
		:class="{ 'product-card--top-attrs': hasAttrsCnf }"
		:is="`ProductCard-${compName}`"
		v-bind="$attrs"
		v-on="$listeners"
		:type="type"
		:featured="featured"
		:small="small"
		:elementOrder="sqOrder"
	/>
</template>

<style lang="scss" scoped>
::v-deep .product-card {
	z-index: 1;
	transition: transform 0.2s ease-out;

	&:hover {
		transform: scale(1.02);
	}
}

::v-deep .btnHoverVertical {
	position: absolute;
	bottom: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	transform: translateY(15px);
	transition: opacity 0.4s, transform 0.2s ease-out;

	&--show {
		opacity: 1;
		transform: translateY(0px);
		visibility: visible;
		width: 100%;
	}
}

::v-deep .btnHoverHorizontal {
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.4s;

	&--show {
		opacity: 1;
		visibility: visible;
	}
}

::v-deep a.card-anchor:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 1;
	left: 0;
}

::v-deep div:has(.qty-btn__container) {
	z-index: 2;
}

// .product-card--top-attrs {
// 	::v-deep img.ratio-image__image {
// 		height: calc(100% - 65px);
// 		bottom: 20px;
// 		top: unset;
// 	}
// }
</style>

