<script>
export default {
	inject: ['manager'],
	computed: {
		hasUniqueVariant() {
			return this.manager.product.hasUniqueVariant
		},
		variant() {
			return this.manager.product.variants[0]
		},
		collectionMatch() {
			const config = this.$srv('productCardQtySelector', [])

			if (!config.length) return false
			return this.manager.product.collectionsIds.some((id) => config.some((c) => c.collectionId == id))
		},
		maxStockQty() {
			let { maxBuyableQty, infiniteQty, qty } = this.variant.stock
			if (maxBuyableQty >= 1) {
				if (infiniteQty) return maxBuyableQty
				else return Math.min(qty, maxBuyableQty)
			} else {
				if (infiniteQty) return 0
				else return qty
			}
		},
	},
}
</script>

<template>
	<div
		v-if="$srv('productCardQtySelector', []).length && manager.hasBtn"
		class="product-card__qty-input"
		:class="{ '--mobile': $b.m }"
	>
		<QtyInput
			v-if="
				hasUniqueVariant &&
				variant.type != 'digital' &&
				collectionMatch &&
				!manager.outOfStock &&
				manager.product.buyable &&
				!manager.spoHideQtyInput
			"
			v-model="manager.qty"
			:max-qty="maxStockQty"
			:min-qty="variant.stock.minBuyableQty"
			:unit-metric="manager.product.unitMetric"
			:pack-metric="manager.product.packMetric"
			:step="variant.metricFactor"
		/>
	</div>
</template>

<style lang="scss" scoped>
.product-card__qty-input {
	&.--mobile ::v-deep {
		.qty-btn {
			&__plus {
				width: 25px;
			}
			&__minus {
				width: 25px;
			}
			&__tf {
				width: unset !important;
			}
		}
	}
}
</style>

