var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tags.length)?_c('div',{staticClass:"product-card__attrs-tags"},[(_vm.resultantAttrs && _vm.resultantAttrs.length)?_c('div',[(_vm.verticalCard)?_c('v-container',{staticClass:"pa-0 pt-1",class:[_vm.$bem('product-card-tags-vertical'), _vm.verticalClasses]},[_c('div',{class:_vm.$bem('__attrs-wrapper')},[(_vm.resultantAttrs)?_c('div',{staticClass:"d-flex align-center",class:{
						'justify-center': _vm.manager.configData.alignment == 'center' && !_vm.top,
						'justify-end': _vm.manager.configData.alignment == 'right' && !_vm.top,
						'px-4': _vm.top && !_vm.manager.small,
						'py-1': _vm.manager.small,
					}},_vm._l((_vm.resultantAttrs),function(ref,index){
					var v = ref.v;
return _c('div',{key:index,staticClass:"d-flex flex-column"},[_c('span',{staticClass:"px-1 font-0 mr-1 secondary font-weight-medium d-inline-block line-clamp-1 py-1",class:[
								_vm.$bem('__attr-value'),
								{ 'product-card-tags-vertical__attr-value--max-width-mobile': _vm.$b.m } ]},[_vm._v(" "+_vm._s(v)+" ")])])}),0):_vm._e()])]):_c('v-container',{staticClass:"pa-0 mb-1",class:[_vm.$bem('product-card-tags-horizontal'), _vm.horizontalClasses]},[(_vm.resultantAttrs)?_c('div',{staticClass:"d-flex align-center"},_vm._l((_vm.resultantAttrs),function(ref,index){
							var v = ref.v;
return _c('div',{key:index,staticClass:"d-flex flex-column my-1"},[_c('span',{staticClass:"px-2 font-0 mr-1 secondary font-weight-medium d-inline-block line-clamp-1",class:_vm.$bem('__attr-value')},[_vm._v(" "+_vm._s(v)+" ")])])}),0):_vm._e()])],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }