var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.verticalCard)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.forMobileShop && _vm.onLinkBlurMobile),expression:"forMobileShop && onLinkBlurMobile"}],staticClass:"h100",class:_vm.wrapperClass},[_c('v-hover',{model:{value:(_vm.hover),callback:function ($$v) {_vm.hover=$$v},expression:"hover"}},[_c('v-card',{staticClass:"d-flex flex-column rounded-md overflow-hidden base product-card",class:[
				_vm.$bem('product-card-design8-small'),
				{ cardBorder: _vm.cardBorder, 'justify-space-between': !_vm.btnHover } ],style:({ height: _vm.forMobileShop && _vm.btnHover && _vm.hover ? 'calc(100% + 50px)' : '100%' }),attrs:{"hover":!_vm.outOfStock ? true : false,"elevation":"2","ripple":false},on:{"mouseover":function($event){_vm.firstImageHover = true},"click":_vm.cardClick}},[_c('div',{class:[_vm.$bem('__image-cont'), { 'no-stock': _vm.outOfStock }, 'mx-2 mt-2']},[_c('hook',{attrs:{"zone":"image"}},[_c('Media',{class:_vm.$bem('__main-image'),attrs:{"cover":"","src":_vm.mainImage,"width":"100%","aspect-ratio":"1","imgClass":_vm.$b.mt
								? 'product-card-design8-small__image--smallWidth-mobile'
								: 'product-card-design8-small__image--smallWidth'}}),(_vm.secondImage && _vm.firstImageHover && _vm.imgHover)?_c('div',{class:_vm.$bem('__second-image-cont')},[_c('Media',{class:[_vm.$bem('__second-image'), _vm.hover ? _vm.$bem('__second-image--show') : ''],attrs:{"cover":"","src":_vm.secondImage,"width":"100%","imgClass":_vm.$b.mt
									? 'product-card-design8-small__image--smallWidth-mobile'
									: 'product-card-design8-small__image--smallWidth'}})],1):_vm._e()],1),_c('hook',{attrs:{"zone":"offer"}},[(_vm.hasLimitedTimeOffer)?_c('LimitedTimeOffer',{class:_vm.$bem('__offer', 'px-1'),attrs:{"product":_vm.product,"textAlign":"end"}}):_vm._e()],1),_c('CollectionEventIcon',{class:_vm.$bem('__event-icon'),attrs:{"product":_vm.product,"imgWidth":"25px"}})],1),_c('div',{class:[
					_vm.$bem('__data-cont'),
					'd-flex flex-column mx-2',
					{
						'no-stock': _vm.outOfStock,
						'justify-space-between': _vm.btn && _vm.forMobileShop,
						h100: !_vm.btnHover,
					} ]},[_c('hook',{attrs:{"zone":"brand"}},[_c('div',{class:_vm.$bem('__brand-wrapper')},[_c('ProductCardText',{attrs:{"textClass":[
								_vm.$bem('__brand'),
								'font-0 line-clamp-1 px-1 mt-2',
								_vm.alignment == 'left'
									? 'text-start'
									: _vm.alignment == 'center'
									? 'text-center'
									: 'text-end' ],"conditional":!!_vm.brand,"text":_vm.brand}})],1)]),_c('router-link',{attrs:{"to":_vm.productRoute,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
							var navigate = ref.navigate;
							var href = ref.href;
return [_c('a',{staticClass:"card-anchor header",attrs:{"href":href},on:{"click":function($event){_vm.forMobileShop ? _vm.onLinkClickMobile(navigate, $event) : navigate($event)}}},[_c('hook',{attrs:{"zone":"name"}},[_c('ProductCardText',{attrs:{"textClass":[
									_vm.$bem('__name'),
									'line-clamp-2 font-1 header px-1',
									_vm.alignment == 'left'
										? 'text-start'
										: _vm.alignment == 'center'
										? 'text-center'
										: 'text-end',
									{ 'mt-2': !_vm.brand } ],"text":_vm.product.name}})],1)],1)]}}],null,false,1052992753)}),_c('ProductCardPrice',{attrs:{"product":_vm.product,"currency":_vm.currency,"priceAmount":_vm.price,"prevPriceAmount":_vm.prevPrice,"discountPct":_vm.discountPct,"containerClass":[
						'd-flex align-center px-1 line-clamp-1',
						{
							'justify-center': _vm.alignment == 'center',
							'justify-end': _vm.alignment == 'right',
						} ],"prevPriceClass":[_vm.$bem('__prev-price'), 'grey--text font-0 ml-1'],"priceClass":[_vm.$bem('__price'), 'price font-3 line-clamp-1'],"discountClass":[_vm.$bem('__discount'), 'discount pa-2']}}),_c('hook',{attrs:{"zone":"attrs&financingTags"}},[_c('ProductCard-FinancingTag')],1),((!_vm.hasTextTag && _vm.forMobileShop) || (!_vm.financingTagHtml && _vm.forMobileShop))?_c('v-spacer'):_vm._e(),_c('hook',{attrs:{"zone":"textTags"}},[_c('ProductCardText',{attrs:{"textClass":[
							_vm.$bem('__text-tags'),
							'font-0 line-clamp-1 px-1 primary rounded',
							{
								'alignment-center': _vm.alignment == 'center',
								'alignment-right': _vm.alignment == 'right',
								'mb-1': !_vm.mobileBtn,
							} ],"conditional":_vm.hasTextTag,"text":_vm.textTag}})],1),_c('ProductCardRating',{attrs:{"product":_vm.product}}),_c('ProductCard-AttrsTags'),(_vm.mobileBtn && !_vm.$b.d && _vm.hasBtn)?_c('div',{staticClass:"pa-3",class:{
						btnHoverVertical: _vm.btnHover && _vm.forMobileShop,
						'btnHoverVertical--show': _vm.btnHover && _vm.hover && _vm.forMobileShop,
						z1: _vm.product.hasUniqueVariant && !_vm.product.info.customizationKey,
					}},[_c('hook',{attrs:{"zone":"btn"}},[_c('v-btn',{staticClass:"cta w100 rounded-md",attrs:{"disabled":_vm.buttonText == 'Sin stock',"loading":_vm.btnIsLoading},on:{"click":_vm.btnClicked}},[_vm._v(" "+_vm._s(_vm._f("lang")(_vm.buttonText))+" ")])],1)],1):_vm._e(),_c('hook',{class:{ 'no-stock': _vm.outOfStock },attrs:{"zone":"wishlist-toggler"}},[_c('ProductWishlistToggler',{class:_vm.$bem('__wishlist-toggler'),staticStyle:{"z-index":"1"},attrs:{"productId":_vm.product.id}})],1)],1)])],1)],1):_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.forMobileShop && _vm.onLinkBlurMobile),expression:"forMobileShop && onLinkBlurMobile"}],staticClass:"w100",class:{
		'product-card-design8-small-horizontal-wrapper-forBtn': _vm.btnHover,
	}},[_c('v-hover',{model:{value:(_vm.hover),callback:function ($$v) {_vm.hover=$$v},expression:"hover"}},[_c('v-card',{staticClass:"d-flex overflow-hidden base product-card py-2",class:[
				_vm.$bem('product-card-design8-small-horizontal'),
				{ 'product-card-design8-small-horizontal-forBtn': _vm.hasBtn, cardBorder: _vm.cardBorder } ],attrs:{"hover":!_vm.outOfStock ? true : false,"outlined":"","ripple":false},on:{"mouseover":function($event){_vm.firstImageHover = true},"click":_vm.cardClick}},[_c('div',{class:[
					_vm.$bem('__image-cont', 'col col-5 pa-0 d-flex flex-column justify-center'),
					{ 'no-stock': _vm.outOfStock } ]},[_c('hook',{staticClass:"h100 p-relative",attrs:{"zone":"imageHorizontal"}},[_c('Media',{class:_vm.$bem('__main-image', 'px-1 h100'),attrs:{"src":_vm.mainImage,"width":"100%","imgClass":"product-card-design8-small-horizontal__image--smallWidth"}}),(_vm.secondImage && _vm.firstImageHover && _vm.imgHover)?_c('div',{class:_vm.$bem('__second-image-cont', 'px-1 h100')},[_c('Media',{class:[
								_vm.$bem('__second-image', 'h100'),
								_vm.hover ? _vm.$bem('__second-image--show') : '',
								'h100' ],attrs:{"src":_vm.secondImage,"width":"100%","imgClass":"product-card-design8-small-horizontal__image--smallWidth"}})],1):_vm._e()],1),_c('hook',{attrs:{"zone":"offerHorizontal"}},[_c('LimitedTimeOffer',{staticClass:"pa-1 line-clamp-1",class:_vm.$bem('__offer'),attrs:{"product":_vm.product,"textAlign":"end"}})],1),_c('hook',{attrs:{"zone":"event-iconHorizontal"}},[_c('CollectionEventIcon',{class:_vm.$bem('__event-icon'),attrs:{"product":_vm.product,"imgWidth":"30px"}})],1)],1),_c('div',{staticClass:"col col-7 py-0 pl-1 d-flex flex-column justify-center",class:[_vm.$bem('__data-cont'), { 'no-stock': _vm.outOfStock, 'my-5': !_vm.hasBtn }]},[_c('hook',{attrs:{"zone":"brand"}},[_c('div',{class:_vm.$bem('__brand-wrapper')},[_c('ProductCardText',{attrs:{"textClass":[_vm.$bem('__brand'), 'font-0 line-clamp-1', { 'mt-5': _vm.hasBtn }],"conditional":!!_vm.brand,"text":_vm.brand}})],1)]),_c('router-link',{attrs:{"to":_vm.productRoute,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
							var navigate = ref.navigate;
							var href = ref.href;
return [_c('a',{staticClass:"card-anchor header",attrs:{"href":href},on:{"click":function($event){_vm.forMobileShop ? _vm.onLinkClickMobile(navigate, $event) : navigate($event)}}},[_c('hook',{attrs:{"zone":"nameHorizontal"}},[_c('ProductCardText',{attrs:{"textClass":[
									_vm.$bem('__name'),
									'line-clamp-2 font-2 header',
									{ 'mt-5': _vm.hasBtn && !_vm.brand } ],"text":_vm.product.name}})],1)],1)]}}])}),_c('ProductCardPrice',{attrs:{"product":_vm.product,"currency":_vm.currency,"priceAmount":_vm.price,"prevPriceAmount":_vm.prevPrice,"discountPct":_vm.discountPct,"containerClass":'d-flex align-center line-clamp-1 mb-1',"prevPriceClass":['grey--text font-0 ml-1', _vm.$bem('__prev-price')],"priceClass":[_vm.$bem('__price'), 'price font-4 line-clamp-1'],"discountClass":[_vm.$bem('__discount'), 'discount pa-2'],"horizontalCard":""}}),_c('hook',{attrs:{"zone":"attrs&financingTagsHorizontal"}},[_c('div',{class:_vm.$bem('__financing-wrapper')},[(_vm.financingTagHtml)?_c('div',{class:[_vm.$bem('__financing'), 'font-0 line-clamp-1 px-1 primary rounded'],domProps:{"innerHTML":_vm._s(_vm.financingTagHtml)}}):_vm._e()])]),_c('hook',{attrs:{"zone":"textTagsHorizontal"}},[_c('ProductCardText',{attrs:{"textClass":[_vm.$bem('__text-tags'), 'font-0 line-clamp-1 px-1 primary rounded'],"conditional":_vm.hasTextTag,"text":_vm.textTag}})],1),_c('hook',{attrs:{"zone":"wishlist-togglerHorizontal"}},[_c('ProductWishlistToggler',{class:[_vm.$bem('__wishlist-toggler'), { 'no-stock': _vm.outOfStock }],staticStyle:{"z-index":"1"},attrs:{"productId":_vm.product.id,"height":30,"width":30}})],1),_c('ProductCardRating',{attrs:{"product":_vm.product}}),(_vm.hasBtn && _vm.mobileBtn)?_c('div',{staticClass:"py-2",class:{
						btnHoverHorizontal: _vm.btnHover && _vm.forMobileShop,
						'btnHoverHorizontal--show': (_vm.btnHover && _vm.showBtnHoverMobile) || (_vm.btnHover && _vm.hover),
						z1: _vm.product.hasUniqueVariant && !_vm.product.info.customizationKey,
					}},[_c('hook',{attrs:{"zone":"btn"}},[_c('v-btn',{staticClass:"cta w100 rounded-md",attrs:{"disabled":_vm.buttonText == 'Sin stock',"loading":_vm.btnIsLoading},on:{"click":_vm.btnClicked}},[_vm._v(" "+_vm._s(_vm._f("lang")(_vm.buttonText))+" ")])],1)],1):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }